import WebServiceRequest from "./WebServiceRequest";

class GetLinksListForAdmin extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/GetListForAdmin");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetReferredUsersListForAdmin extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/GetUserListForAdmin");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetCashoutRequestsForAdmin extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/GetRequestListForAdmin");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class ConfirmACashoutRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/ConfirmRequest");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetCommissionCashoutRequestsForAdmin extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/GetRequestListForAdminComission");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class ConfirmCommissionCashoutRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("UserInviteLink/ConfirmRequestForComission");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  GetLinksListForAdmin,
  GetReferredUsersListForAdmin,
  GetCashoutRequestsForAdmin,
  ConfirmACashoutRequest,
  GetCommissionCashoutRequestsForAdmin,
  ConfirmCommissionCashoutRequest,
};
